<template>
  <div>
    <v-container class="mb-4">
      <v-row>
        <v-btn-toggle
          v-model="view"
          dense
          mandatory
          v-if="!$_hasRole('student')"
        >
          <v-btn text value="panel">Spiegel</v-btn>
          <v-btn text value="list">Liste</v-btn>
        </v-btn-toggle>

        <StudentGroupFilter
          :course="course"
          v-model="studentsFiltered"
        ></StudentGroupFilter>

        <v-spacer></v-spacer>
        <EmailChip
          v-if="course.students"
          class="mr-2"
          title="E-Mail an alle"
          :value="course.students.map((el) => el.emailSchool).join(',')"
        ></EmailChip>
        <EmailChip
          v-if="
            course.students && course.students.length != studentsFiltered.length
          "
          title="E-Mail an sichtbare"
          :value="studentsFiltered.map((el) => el.emailSchool).join(',')"
        ></EmailChip>
      </v-row>
    </v-container>

    <v-card v-if="view == 'list' || $_hasRole('student')">
      <v-system-bar>Kursliste</v-system-bar>

      <v-list v-if="$_hasRole('student')">
        <PersonItem
          v-for="student in course.students"
          :value="student"
          :key="student.id"
        >
        </PersonItem>
      </v-list>

      <v-list v-else-if="$vuetify.breakpoint.xs">
        <PersonItem
          v-for="student in studentsFiltered"
          :value="student"
          :key="student.id"
        >
          <StudentGroupChips
            @updated="$emit('updated')"
            :student="student"
            :course="course"
            :studentGroups="studentGroups"
          />
          <template slot="listitems">
            <v-list-item>
              <ListIcon><v-icon>mdi-account-multiple-plus</v-icon></ListIcon>
              <v-list-item-content>
                <v-list-item-title>Kurs-Gruppen</v-list-item-title>
              </v-list-item-content>

              <StudentGroupChips
                @updated="$emit('updated')"
                :student="student"
                :course="course"
                :studentGroups="studentGroups"
              />
            </v-list-item>
          </template>
        </PersonItem>
      </v-list>

      <v-data-table
        v-else
        :items="studentsFiltered"
        :headers="headers"
        disable-filtering
        disable-pagination
        hide-default-footer
      >
        <template v-slot:item.student="{ item }">
          <PersonItem small hideExt :value="item" flags>
            <template slot="listitems">
              <v-list-item>
                <ListIcon><v-icon>mdi-account-multiple-plus</v-icon></ListIcon>
                <v-list-item-content>
                  <v-list-item-title>Kurs-Gruppen</v-list-item-title>
                </v-list-item-content>
                <StudentGroupChips
                  @updated="$emit('updated')"
                  :student="item"
                  :course="course"
                  :studentGroups="studentGroups"
              /></v-list-item> </template
          ></PersonItem>
        </template>
        <template v-slot:item.absences="{ item }">
          <span v-if="item.absences > 0">{{ item.absences }}</span>
        </template>
        <template
          v-for="headerItem in headers.filter((item) => item.description)"
          v-slot:[`header.${headerItem.value}`]="{ header }"
        >
          <v-tooltip top :key="'header' + headerItem.value">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ header.text }} </span>
            </template>
            <span>{{ header.description }}</span>
          </v-tooltip>
        </template>
        <template
          v-for="headerItem in headers.filter((item) => item.lookup)"
          v-slot:[`item.${headerItem.value}`]="{ value }"
        >
          <v-tooltip top :key="'item' + headerItem.value">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"
                >{{ value ? value.code : "" }}
              </span>
            </template>
            <span>{{ value ? value.description : "" }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.studentGroups="{ item }">
          <StudentGroupChips
            @updated="$emit('updated')"
            :student="item"
            :course="course"
            :studentGroups="studentGroups"
          />
        </template>
        <template v-slot:footer
          ><TableFooter
            :items="studentsFiltered"
            label="Schüler:innen"
            labelSingular="Schüler:in"
        /></template>
      </v-data-table>
    </v-card>

    <v-card v-if="view == 'panel'">
      <v-system-bar>Kursspiegel</v-system-bar>
      <v-card-text>
        <PeoplePanel :value="studentsFiltered" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import EmailChip from "common/components/EmailChip.vue";
import PersonItem from "common/components/PersonItem.vue";
import PeoplePanel from "common/components/PeoplePanel.vue";
import StudentGroupChips from "./components/StudentGroupChips.vue";
import StudentGroupFilter from "./components/StudentGroupFilter.vue";
import TableFooter from "common/components/TableFooter.vue";

export default defineComponent({
  name: "CourseList",
  components: {
    EmailChip,
    PersonItem,
    PeoplePanel,
    StudentGroupChips,
    StudentGroupFilter,
    TableFooter,
  },
  props: { course: {}, studentGroups: [] },
  data() {
    return {
      view: "list",
      studentsFiltered: [],
      headers: [
        {
          text: "Klasse",
          value: "schoolClass.code",
        },
        {
          text: "Name",
          value: "student",
        },
        {
          text: "BI",
          description: "zweisprachiger Unterricht",
          value: "bilingual",
          lookup: true,
          sort: this.compareLookup,
        },
        {
          text: "KU",
          description: "Kunstfach",
          value: "artsSubject",
          lookup: true,
          sort: this.compareLookup,
        },
        {
          text: "S3",
          description: "Dritte Sprache",
          value: "thirdLanguage",
          lookup: true,
          sort: this.compareLookup,
        },
        {
          text: "SF",
          description: "Schwerpunktfach",
          value: "majorSubject",
          lookup: true,
          sort: this.compareLookup,
        },
        {
          text: "SF+",
          description: "Schwerpunktfach plus",
          value: "majorSubjectPlus",
          lookup: true,
          sort: this.compareLookup,
        },
        {
          text: "EF",
          description: "Ergänzungsfach",
          value: "complementarySubject",
          lookup: true,
          sort: this.compareLookup,
        },
        { text: "Absenzen", value: "absences" },
        { text: "Gruppen", value: "studentGroups", align: "end" },
      ],
    };
  },
  methods: {
    compareLookup(a, b) {
      return a.code.localeCompare(b.code, "de-CH");
    },
  },
});
</script>
